import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectTurnoServicio (Vue, filter, search, sorter, page, idservicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idpuesto_servicio_servicio', idservicio)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.descripcion.value) {
      apiFilter.addILike(filter.descripcion.field, filter.descripcion.value)
    }
    const resp = await Vue.$api.call('tturnoPuestoServicio.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectselectTurnoServicioRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idtturno_puesto_servicio', pks)
    const resp = await Vue.$api.call('tturnoPuestoServicio.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
